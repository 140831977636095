export default [
    "$table",
    "$dateCol",
    "$dateTimeCol",
    "$from",
    "$to",
    "$interval",
    "$timeFilter",
    "$timeFilterMs",
    "$timeFilterByColumn",
    "$timeFilter64ByColumn",
    "$timeSeries",
    "$timeSeriesMs",
    "$naturalTimeSeries",
    "$rate",
    "$perSecond",
    "$perSecondColumns",
    "$delta",
    "$deltaColumns",
    "$increase",
    "$increaseColumns",
    "$columns",
    "$rateColumns",
    "$unescape",
    "$adhoc",
    "$conditionalTest"
];
